<template>
  <div class="meet-list">
    <el-breadcrumb class="meet-list-breadcrumb" separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">{{dict[language].home}}</el-breadcrumb-item>
      <el-breadcrumb-item><a @click="refresh">{{dict[language].schedule}}</a></el-breadcrumb-item>
    </el-breadcrumb>
    <div class="meet-list-breadcrumb-title" >{{dict[language].schedule}}</div>
    <!-- logo区域 -->
    <div class="meet-list-cards">
      <div class="meet-list-card" v-for="meet in dataList" :key="meet.id">
        <div class="meet-list-card-top">
          <span>{{meet.title}}</span>
        </div>
        <div class="meet-list-card-bottom">
          <span class="meet-list-card-addr">{{meet.addrName}}</span>
          <span class="meet-list-card-time">{{computeTime(meet)}}</span>
          <router-link tag="div" class="meet-list-card-action" :to="`/meetList/${meet.id}`">{{dict[language].detail}}</router-link>
        </div>
      </div>
    </div>
    <div class="query-pagination">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="dataForm.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="dataForm.limit"
        layout="total, prev, pager, next"
        :total="total"
        center
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import mixs from '@/mixs'
import dict from '@/assets/json/dictionaries.json'
import {getSwMeet} from '@/http/request'
export default {
  name: "MeetList",
  inject: ['language'],
  mixins: [mixs],
  data() {
    return {
      dict,
      total: 0,
      dataList: [],
      dataForm: {
        language: 0,
        limit: 20,
        page: 1
      }
    }
  },
  created() {
    this.dataForm.language = this.language === 'zh' ? 0 : 1;
    this.getSwMeet()
  },
  methods: {
    refresh(){
      this.reload()
    },
    getSwMeet() {
      getSwMeet({language: this.language === 'zh' ? 0 : 1, ...this.dataForm}).then(({data}) => {
        if(data.code === 0) {
          this.dataList = data.data.list;
          this.total = data.data.total;
        }
      })
    },

    handleCurrentChange(n) {
      this.dataForm.page = n;
      this.getSwMeet();
    },

    computeTime({startTime, endTime}) {
      console.log(startTime, endTime)
      const st = startTime.replace(/-/g, '.').split(" ")[0];
      const et = endTime.replace(/-/g, '.').split(" ")[0];
      return `${st} - ${et}`
    }
  }
};
</script>

<style lang='less'>
.meet-list {
  width: 933px;
  margin: 54px auto;
  .meet-list-breadcrumb {
    margin: 25px 0 60px;
  }
  .meet-list-breadcrumb-title{
    margin-bottom: 40px;
    font-size: 30px;
    color: #2F6D8B;
  }
  .meet-list-cards {
    margin-bottom: 50px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    .meet-list-card {
      width: 220px;
      height: 250px;
      float: left;
      padding: 30px 10px 20px;
      margin: 0 17px 12px 0;
      box-sizing: border-box;
      border-radius: 8px;
      background: #fff;
      position: relative;
      overflow: hidden;
      border: 1px solid #dcdfe6;
      &:nth-child(4n + 0) {
        margin-right: 0;
      }
      .meet-list-card-top {
        width: 100%;
        height: 80px;
        padding: 15px 15px 0 15px;
        margin: 0 auto;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        span {
          width: 100%;
          height: max-content;
          max-height: 65px;
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          color: #727171;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
      .meet-list-card-bottom {
        width: 100%;
        height: 60%;
        position: absolute;
        bottom: 0;
        left: 0;
        .meet-list-card-addr {
          width: 100%;
          padding: 0 15px;
          display: block;
          box-sizing: border-box;
          text-align: center;
          font-size: 13px;
          font-weight: 500;
          color: #727171;
        }

        .meet-list-card-time {
          width: 100%;
          padding: 12px 15px;
          display: block;
          box-sizing: border-box;
          text-align: center;
          font-size: 13px;
          font-weight: 500;
          color: #727171;
        }

        .meet-list-card-action {
          width: 117px;
          height: 37px;
          line-height: 37px;
          margin: 15px auto 20px;
          text-align: center;
          border-radius: 99px;
          border: 1px solid #f3f3f3;
          font-size: 14px;
          color: #727171;
          cursor: pointer;
          &:hover {
            border: 1px solid transparent;
            background: #1499dc;
            color: #fff;
          }
        }
      }
      &:hover {
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
        cursor: pointer;
        transition: box-shadow 0.3s;
      }
      &::after {
        width: 100%;
        height: 4px;
        background: linear-gradient(90deg, #1499dc, #283885);
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
      }
    }
  }
}
</style>
